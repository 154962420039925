import React from "react"
import { Link } from "gatsby"

const AboutPage = () => (
  <>
    <h1>About</h1>
    <p>Information about my self</p>
    <p>
      Need a developer? <Link to="/contact">Contact me</Link>
    </p>
  </>
)

export default AboutPage
